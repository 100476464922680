$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
//$fa-font-path: '~font-awesome/fonts/';
@import "~font-awesome/css/font-awesome.min.css";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

//@import '../vendors/bootstrap/css/bootstrap.css';
//@import '../vendors/plugins/font-awesome/css/font-awesome.min.css';
//@import '../vendors/plugins/EasyAutocomplete/easy-autocomplete.min.css';
//@import '../vendors/plugins/AlertifyJS/css/alertify.min.css';
//@import '../vendors/plugins/AlertifyJS/css/themes/bootstrap.min.css';

// pick the Lato fonts individually to avoid importing the entire font family
@import '~lato-font/scss/public-api';
@include lato-include-font('normal');
@include lato-include-font('bold');

//Common
@import '../vendors/plugins/FileUpload/css/jquery.fileupload.css';
@import "~blueimp-file-upload/css/jquery.fileupload.css";
@import '../vendors/plugins/loading/dist/loading.min.css';
